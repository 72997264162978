/* Webfont: LatoLatin-Black */
@font-face {
  font-family: 'LatoLatinWebBlack';
  src: url('lato/LatoLatin-Black.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Heavy */
@font-face {
  font-family: 'LatoLatinWebHeavy';
  src: url('lato/LatoLatin-Heavy.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Bold */
@font-face {
  font-family: 'LatoLatinWeb';
  src: url('lato/LatoLatin-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Semibold */
/* @font-face {
  font-family: "LatoLatinWebSemibold";
  src: url("lato/LatoLatin-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-Medium */
@font-face {
  font-family: 'LatoLatinWebMedium';
  src: url('lato/LatoLatin-Medium.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Regular */
@font-face {
  font-family: 'LatoLatinWeb';
  src: url('lato/LatoLatin-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: LatoLatin-Light */
@font-face {
  font-family: 'LatoLatinWebLight';
  src: url('lato/LatoLatin-Light.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: LatoLatin-Thin */
/* @font-face {
  font-family: "LatoLatinWebThin";
  src: url("lato/LatoLatin-Thin.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */
/* Webfont: LatoLatin-Hairline */
@font-face {
  font-family: 'LatoLatinWebHairline';
  src: url('lato/LatoLatin-Hairline.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: LatoLatin-BlackItalic */
/* @font-face {
  font-family: "LatoLatinWebBlack";
  src: url("lato/LatoLatin-BlackItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-BoldItalic */
/* @font-face {
  font-family: "LatoLatinWeb";
  src: url("lato/LatoLatin-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-HairlineItalic */
/* @font-face {
  font-family: "LatoLatinWebHairline";
  src: url("lato/LatoLatin-HairlineItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-HeavyItalic */
/* @font-face {
  font-family: "LatoLatinWebHeavy";
  src: url("lato/LatoLatin-HeavyItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-Italic */
/* @font-face {
  font-family: "LatoLatinWeb";
  src: url("lato/LatoLatin-Italic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-LightItalic */
/* @font-face {
  font-family: "LatoLatinWebLight";
  src: url("lato/LatoLatin-LightItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-MediumItalic */
/* @font-face {
  font-family: "LatoLatinWebMedium";
  src: url("lato/LatoLatin-MediumItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-SemiboldItalic */
/* @font-face {
  font-family: "LatoLatinWebSemibold";
  src: url("lato/LatoLatin-SemiboldItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */

/* Webfont: LatoLatin-ThinItalic */
/* @font-face {
  font-family: "LatoLatinWebThin";
  src: url("lato/LatoLatin-ThinItalic.woff") format("woff");
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} */
