/* @import '/Users/emmanuel/Projects/websiteAnimated/node_modules/lato/font/lato-black/lato-black.woff2'; */
@import './fonts/lato.css';
@import './styles/ui.css';

* {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
html {
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
}
body {
  position: relative;
  background-color: #333;
  height: 100%;
  font-family: 'LatoLatinWeb', 'Open Sans', 'Helvetica', sans-serif;
}
:root {
  --persp: 436px;
  --minv: 1;
}
#container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#world {
  pointer-events: none;
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /* backface-visibility: hidden; */
  /* Set the scene perspective both lines go together */
  perspective: 450px;
  transform-style: preserve-3d;
  /* 4 DEBUGGING */
  /*top*/
  /* transform:rotate3d( 1, 0, 0, -90deg); */
  /*side*/
  /* transform:rotate3d( 0, 1, 0, -90deg); */
  /* transform:rotate3d( 0, 1, 0, -45deg); */
  /* transform:rotateX(-30deg) rotateY(-45deg); */
  /* transform:rotate3d( 0, 1, 0, 0deg); */
  /*none*/
  /* transform:rotate3d( 0, 1, 0, 0); */
}
#fgs,
#bgs {
  position: absolute;
  width: 100%;
  height: 100%;
}
#fgs *,
#bgs * {
  visibility: hidden;
  position: absolute;
  transform-origin: 0 0;
  image-rendering: pixelated;
}
#bgColor {
  position: absolute;
  width: 10vw;
  height: 10vh;
  transform: scale(10);
  background-color: #333;
  image-rendering: pixelated;
}
#spinners {
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: 0 0;
}
.spinner {
  visibility: hidden;
  position: absolute;
  transform-origin: 0 0;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: pixelated;
  /* background-image: url(./images/Spinners.png); */
  /* background-repeat: no-repeat; */
  /* background-size: 170px 17px */
  visibility: hidden;
}

/* My Shapes */
.rect {
  visibility: hidden;
  position: absolute;
  transform: scale(10);
  width: 10%;
  height: 10%;
  transform-origin: 0 0;
}
.ring,
.circ {
  visibility: hidden;
  width: 100vmin;
  height: 100vmin;
}
#world p {
  position: absolute;
  transform-style: preserve-3d;
  color: rgb(26, 21, 4);
  font-family: 'LatoLatinWebHairline';
}

span {
  position: absolute;
  transform-origin: 0 0;
}

#hello {
  visibility: hidden;
  position: absolute;
  color: #212121;
  font-size: 18vmin;
  font-family: 'LatoLatinWebHeavy';
  text-transform: lowercase;
}

#there {
  visibility: hidden;
  position: absolute;
  color: #212121;
  font-size: 18vmin;
  font-weight: 200;
  font-family: 'LatoLatinWebLight';
}
#iamPapa {
  position: absolute;
  transform-style: preserve-3d;
}
#iam {
  visibility: hidden;
  position: absolute;
  background-color: #f9a825;
  font-size: 7vmin;
  font-family: 'LatoLatinWebHeavy';
  text-transform: uppercase;
  padding: 7vmin 6vmin;
}

#eme {
  visibility: hidden;
  position: absolute;
  color: #f9a825;
  font-size: 9vmin;
  font-family: 'LatoLatinWebHeavy';
  letter-spacing: 0vmin;
  text-transform: uppercase;
  background-color: #232323;
  padding: 6vmin;
  display: flex;
  transform-style: preserve-3d;
  transform-origin: 0 0;
}
#eme > span {
  visibility: hidden;
  position: relative;
  transform-origin: 0 0;
  padding: 1vmin;
}
#votis {
  position: absolute;
  transform-style: preserve-3d;
}
#votis span {
  visibility: hidden;
  color: #f9a825;
  position: absolute;
  font-size: 5vmin;
  font-family: 'LatoLatinWebHeavy';
  text-transform: uppercase;
}

#a {
  visibility: hidden;
  position: absolute;
  font-size: 5vmin;
  font-family: 'LatoLatinWebMedium';
  text-transform: lowercase;
}

#software {
  visibility: hidden;
  font-size: 10vmin;
  font-family: 'LatoLatinWebHairline';
  letter-spacing: 1.5vmin;
  text-transform: uppercase;
}

#dev {
  visibility: hidden;
  font-size: 6vmin;
  font-family: 'LatoLatinWebHeavy';
  letter-spacing: 3vmin;
  text-transform: uppercase;
}

#ageek {
  visibility: hidden;
  font-size: 44vmin;
  font-family: 'LatoLatinWebHairline';
  color: #212121;
  z-index: 1;
}

.geek {
  visibility: hidden;
  position: absolute;
  color: #424242;
  font-size: 10vmin;
  font-family: 'LatoLatinWebBlack';
}

#by {
  visibility: hidden;
  font-size: 4vmin;
  font-family: 'LatoLatinWebMedium';
  color: #212121;
}
#nature {
  visibility: hidden;
  position: absolute;
  color: #212121;
  font-size: 7.5vmin;
  font-family: 'LatoLatinWebMedium';
  letter-spacing: 5.5vmin;
  text-transform: uppercase;
}

#creativeBy {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate3d(50vw, 50vh, 0) translate3d(-50%, 0, 0);
}
#creativeBy * {
  display: block;
  padding: 3vmin;
}
#creative {
  visibility: hidden;
  position: absolute;
  font-size: 10.5vmin;
  font-family: 'LatoLatinWebMedium';
  letter-spacing: -10vmin;
  text-transform: uppercase;
}

#byheart {
  visibility: hidden;
  position: absolute;
  font-size: 2.5vmin;
  font-family: 'LatoLatinWebHeavy';
}
#heart {
  visibility: hidden;
  position: absolute;
  font-size: 5.5vmin;
  font-family: 'LatoLatinWebHeavy';
  letter-spacing: 9.5vmin;
  text-transform: uppercase;
}

#outro {
  visibility: hidden;
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'LatoLatinWeb';
  background-color: #f9a825;
  /* color: #f9a825; */
  font-size: 11vmin;
  font-weight: 100;
  font-family: 'LatoLatinWebLight';
  line-height: 19vmin;
  padding: 10vmin;
  max-width: 90vmin;
}

#outro * {
  visibility: hidden;
  position: relative;
}

#outro h2 {
  font-size: 6vmin;
  font-family: 'LatoLatinWebHeavy';
  align-self: flex-start;
  position: relative;
  padding: 1vmin 0;
}
#outro p {
  position: relative;
}

#hire {
  position: absolute;
  display: block;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  text-align: center;

  /* width: 22vmin; */
  /* height: 22vmin; */
  /* background-color: #f93025; */
}

#hi {
  visibility: hidden;
  display: block;
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  font-family: 'LatoLatinWebBlack';
  line-height: 22vmin;
  font-size: 11vmin;
  width: 22vmin;
  height: 22vmin;
  color: #2f2f2f;
  background-color: #f9a825;
}

#re {
  visibility: hidden;
  display: block;
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  font-family: 'LatoLatinWebBlack';
  line-height: 22vmin;
  font-size: 11vmin;
  width: 22vmin;
  height: 22vmin;
  color: #2f2f2f;
  background-color: #f9a825;
}

#me {
  visibility: hidden;
  display: block;
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: 0 0;
  font-family: 'LatoLatinWebBlack';
  line-height: 22vmin;
  font-size: 11vmin;
  width: 22vmin;
  height: 22vmin;
  color: #f9a825;
  background-color: #000;
  text-transform: uppercase;
}

p#scroll-up {
  visibility: hidden;
  font-family: 'LatoLatinWebMedium';
  display: block;
  position: absolute;
  transform-origin: 0 0;
  font-size: 4vmin;
  color: #f9a825;
  text-transform: uppercase;
  letter-spacing: 1vmin;
}

#emailme {
  visibility: hidden;
  position: absolute;
  transform-origin: 0 0;
  pointer-events: all;
  cursor: pointer;
  fill: #f9a825;
  width: 15vmin;
  height: 15vmin;
  border-radius: 999px;
  background-color: #2f2f2f;
  padding: 4vmin;
  will-change: fill, background-color, box-shadow;
  transition: all 200ms ease-out;
}
#emailme * {
  pointer-events: all;
  cursor: pointer;
}
#emailme:hover {
  box-shadow: 0 0 8px 4px #0000002e;
  fill: #2f2f2f;
  background-color: #f9a825;
  transform: scale(1.1);
}

.petal {
  visibility: hidden;
  position: absolute;
  transform-origin: 0 0;
  /* mix-blend-mode: color-burn; */
  border-radius: 9999px;
}
