#title {
  position: absolute;
  font-size: 4vmin;
  font-family: 'LatoLatinWebLight';
  pointer-events: all;
  cursor: pointer;
  color: #f9a825;
  background-color: #2f2f2f;
  padding: 4vmin;
}

#subtitle {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  font-family: 'LatoLatinWebLight';
  color: #2f2f2f;
  padding: 2vmin;
}

#timeline {
  position: absolute;
  transform-origin: 0 0;
  transform: translate(0, 100vh) translate(0, -100%);
  height: 3px;
  width: 100%;
  background-color: #f9a825;
}

#time {
  position: absolute;
  left: 10px;
  font-family: 'LatoLatinWebHeavy';
  letter-spacing: 0.7rem;
  font-size: 0.7rem;
  bottom: 14px;
}

#scrolltainer {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* z-index: 9999; */
  user-select: all;
  pointer-events: all;
  position: absolute;
  -webkit-overflow-scrolling: touch;
}
#scrolltent {
  transform: translate(100vw, 0) translate(-100%, 0);
  width: 3px;
  background-color: tomato;
  /* background-image: url('./images/Spinners.png'); */
  height: 1000vh;

  -webkit-overflow-scrolling: touch;
  color: white;
  background: linear-gradient(to bottom, #333, #333 50%, #999 50%, #999);
  background-size: 100% 0px;
}

svg {
  width: 100%;
  height: 100%;
}
